import { createApp } from "vue";
import App from "./App.vue";
import "./index.css";
import "./registerServiceWorker";
import router from "./router";




const app = createApp(App);

app.component('button-counter', {
    data() {
      return {
        count: 0
      }
    },
    template: `
      <button @click="count++">
        You clicked me {{ count }} times.
      </button>`
  });

app.component('save-button', {
    methods: {
        squareOAuth() {
            console.log("");
      }
    },
    template: `<div><button @click="squareOAuth()" id="submit"
    class="w-full py-3 mt-10 bg-gray-800 rounded-sm
    font-medium text-white uppercase
    focus:outline-none rounded-lg bg-green-700 hover:shadow-none">
    Login with Square
    </button></div>`
  });


app.use(router);
app.mount("#app");