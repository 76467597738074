
import { Options, Vue} from "vue-class-component";
import axios, {AxiosResponse} from "axios"
import { defineComponent } from 'vue'

export default defineComponent({
   data() {
    return {
      services: []
    }
  },
   async created() {
    try {
      const response = await axios.get(process.env.VUE_APP_SERVER_LOCATION + '/api/services')
      this.services = response.data
	  console.log(this.services)
    } catch (e) {
      console.log(e)
    }
  } 
})

