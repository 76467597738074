import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "h-screen w-full flex overflow-hidden select-none" }
const _hoisted_2 = { class: "flex-1 bg-gray-200 dark:bg-black rounded-l-lg transition duration-500 ease-in-out overflow-y-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Services = _resolveComponent("Services")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("main", _hoisted_2, [
      _createVNode(_component_Services)
    ])
  ]))
}