
import { Options, Vue} from "vue-class-component";
import axios, {AxiosResponse} from "axios"
axios.defaults.withCredentials = true
import { defineComponent } from 'vue'
import Services from "@/components/Services.vue"; // @ is an alias to /src




export default defineComponent({
   data() {
    return {
      
    }
  },
   async created() {
    try {
      const response = await axios.get(process.env.VUE_APP_SERVER_LOCATION + '/feed?code=' + this.$route.query.code)
      this.$router.replace({ path: "feed" })	
	} catch (e) {
      console.log(e)
    }
  }, 
  components: {
    Services
  },
  methods:{
  }
})

